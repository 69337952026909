<template>
  <div>
    <div class="head">
      <a href="javascript:;" class="more" id="conn_btn" @click="conn">{{ walletAddress }}</a>
    </div>

    <div class="index">
      <div class="section1">
        <div class="img"></div>
        <div class="title"><img src="images/title.png" alt=""></div>
      </div>
      <div class="section2">
        <img src="images/stars.png" class="stars" alt="">
        <div class="wrap">
          <div class="imgbox"><img src="images/img1.png" alt=""></div>
          <div class="right">
            <div class="title">EveOneCat</div>
            <div class="title2">Mint progress</div>
            <div class="loadings">
              <i :style="pro_style"></i>
              <div class="num">{{ pro }}%</div>
            </div>
            <div class="dec">Mint Price: 0.01ETH/1,000,000 $ECAT</div>
            <a href="javascript:;" class="more" id="mint_btn" @click="mint">MINT</a>
          </div>
        </div>
      </div>
      <div class="section_2">
        <img src="images/lines.png" alt="">
      </div>
      <div class="section3">
        <div class="wrap">
          <div class="title">
            <div class="center">
              <img src="images/icon1.png" class="icon1" alt="">
              <img src="images/icon2.png" class="icon2" alt="">
              <div class="txt1">What is a EveOneCat?</div>
              <div class="txt2">Auto to the black hole address</div>
            </div>
          </div>
          <div class="content">
            <div class="left">
              <p>
                Many people talk about fair releases, but in the case of Bitcoin and EVM, most of the rewards and funds are concentrated in the hands of a few.
                <br/>

                We support EVM projects by using Mint Token's back-motion logic, dynamically adjusting the mint price to prevent token concentration. Each time a token is released, the funds increase liquidity.
                <br/>

                We chose a Low-GAS public chain platform to save fuel costs. Despite being called a "social experiment", we oppose experimenting with investor funds because it is irresponsible and can ultimately lead to the loss of funds.
              </p>
            </div>
            <div class="right">
              <img src="images/ic1.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="wrap">
          <div class="title">
            <div class="center">
              <img src="images/icon3.png" class="icon3" alt="">
              <img src="images/icon4.png" class="icon4" alt="">
              <div class="txt1">Tokenomics</div>
              <div class="txt2">50% enters LP 50% launches</div>
            </div>
          </div>
          <div class="dec">
           <p>
             <b>Total Supply</b>: 10,000,000,000 $ECAT
             <br/>
             <b>Initial Price</b>: 0.01ETH/1,000,000 $ECAT
             <br/>

             <b>Maximum Number Of Single-Address Minting</b>: 1
             <br/>
             <br/>

             Liquidity Addition Rules: For every 100 mintings, 0.5ETH+ liquidity will be automatically added 50,000,000 $ECAT
             <br/>
             <br/>

             It is a program that issues new tokens through a minting process. Tokens are minted at a fixed price starting at a specific block height, and the price dynamically adjusts based on the minting history to balance supply and demand and extend the issuance period.
             <br/>
             <br/>

             The minted ETH will be injected into the liquidity pool along with the tokens to ensure market liquidity. The contract prohibits the minting of contract addresses, ensuring that the minter is a real user.
             <br/>
             <br/>

             This model is similar to the successful project Inscription, which aims to engage the community through a large supply and extended issuance period, creating a balanced ecosystem that fosters the token's long-term success.
           </p>
          </div>
        </div>
      </div>
      <div class="section5">
        <div class="link">
          <a href="https://x.com/EveOneCat_Eth" target="_blank"><img src="images/li1.png" alt=""></a>
          <a href="https://t.me/EveOneCAT_1000X" target="_blank"><img src="images/li2.png" alt=""></a>
        </div>
        <div class="center"><img src="images/center.png" alt=""></div>
      </div>
    </div>
    <div class="footer"><img src="images/footer.jpg" width="100%" alt=""></div>
  </div>
</template>

<style>

</style>

<script>

import {ethers} from "ethers";

const contract_abi = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "allowance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
      }
    ],
    "name": "ERC20InsufficientAllowance",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "needed",
        "type": "uint256"
      }
    ],
    "name": "ERC20InsufficientBalance",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "approver",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidApprover",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "receiver",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidReceiver",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidSender",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "ERC20InvalidSpender",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ReentrancyGuardReentrantCall",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "ethAmount",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
      }
    ],
    "name": "AddLiquidityEvent",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "_pair",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getCurrentTokenPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mintEnabled",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mintLimit",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mintPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mintTimes",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "mintsPerAddress",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_numToMint",
        "type": "uint256"
      }
    ],
    "name": "multiMint",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "reserves",
    "outputs": [
      {
        "internalType": "uint112",
        "name": "",
        "type": "uint112"
      },
      {
        "internalType": "uint112",
        "name": "",
        "type": "uint112"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_enabled",
        "type": "bool"
      }
    ],
    "name": "setMintEnabled",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_enabled",
        "type": "bool"
      }
    ],
    "name": "setTransfersEnabled",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "transfersEnabled",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "uniswapFactory",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "uniswapRouter",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "withdrawETH",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "withdrawTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
];
const contract_address = "0x5ff6886c297bf6af1fe40a0d43308ab2cd9e4f82";


export default {
  name: 'HomeView',
  components: {},
  data: () => ({
    'wallet_address': null,
    max: 20000000000.0,
    balance: 20000000000.0,
  }),
  computed: {
    walletAddress() {
      if (this.wallet_address) {
        return this.wallet_address.substr(0, 6) + '...' + this.wallet_address.substr(-2);
      } else {
        return 'Connect';
      }
    },
    pro() {
      return ((Number)(((this.max - this.balance) / this.max).toFixed(4)) * 100).toFixed(2);
    },
    pro_style() {
      const pro = this.pro;
      return 'width: ' + pro + '%';
    }
  },
  methods: {
    async mint() {
      // alert("MINT is closed. Please check official Twitter for details.");
      // return;

      const startTime = new Date("2024-11-17 20:00:00 +8");
      const endTime = new Date("2029-10-22 19:00:00 +8");
      const currTime = new Date();
      if (currTime < startTime) {
        const timeDiff = startTime - currTime;

        const seconds = Math.floor((timeDiff / 1000) % 60);
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        alert(`Remaining time: ${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`);

        return;
      }

      if (currTime >= endTime) {
        alert('closed');
        return;
      }


      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Get the signer
      const wallet = await provider.getSigner();

      // Get the wallet address
      const address = await wallet.getAddress();
      this.wallet_address = address;

      this.wallet_address = address;
      const contract = new ethers.Contract(contract_address, contract_abi, wallet);
      const times = await contract.mintsPerAddress(address);
      console.log("times",times);
      if((Number)(times.toString())>=10){
        alert("Mint limit reached")
        return;
      }

      const price = ethers.utils.parseEther("0.01") // Adjust the value as needed
      console.log("price", price.toString());
      const tx = await contract.multiMint(1,{
        value: price,
        gasLimit: ethers.utils.hexlify(1000000) // Replace 100000 with your desired gas limit
      });
      console.log("tx", tx);

      // alert("Transaction sent:" + tx.hash);
      await tx.wait();
      alert("Transaction confirmed:" + tx.hash);
    },
    async conn() {
      if (this.wallet_address) {
        this.wallet_address = null;
        return;
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Get the signer
      const wallet = await provider.getSigner();

      // Get the wallet address
      const address = await wallet.getAddress();
      this.wallet_address = address;
    },
    async load() {
      // const rpc_url = "https://base-rpc.publicnode.com";
      // const rpc_url = "https://eth.merkle.io";
      // const rpc_url = "https://bsc-dataseed1.binance.org/";
      const rpc_url = 'https://rpc.flashbots.net';

      const provider = new ethers.providers.JsonRpcProvider(rpc_url);
      const contract = new ethers.Contract(contract_address, contract_abi, provider);
      console.log("contract_address",contract_address)
      const balance = await contract.balanceOf(contract_address);
      const balance_a = ethers.utils.formatEther(balance);
      console.log('contract_address_balance', balance_a);
      this.balance = (Number)(balance_a);

      const p = this.pro

      console.log('p', p);

      // const price = await contract.getCurrentTokenPrice();
      // console.log('price', price)


    },
  },
  mounted() {
    setInterval(() => {
      this.load();
    }, 3000);
  }
}
</script>